import { render, staticRenderFns } from "./Sophrologie.vue?vue&type=template&id=14ca5724&scoped=true&"
import script from "./Sophrologie.vue?vue&type=script&lang=js&"
export * from "./Sophrologie.vue?vue&type=script&lang=js&"
import style0 from "./Sophrologie.vue?vue&type=style&index=0&id=14ca5724&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14ca5724",
  null
  
)

component.options.__file = "Sophrologie.vue"
export default component.exports