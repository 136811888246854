var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('md-card',{staticClass:"md-card-tabs",class:[
    {'flex-column': _vm.flexColumn},
    {'nav-pills-icons': _vm.navPillsIcons},
    {'md-card-plain': _vm.plain}
  ]},[_c('md-card-header',{attrs:{"slot":"header-title"},slot:"header-title"}),_c('md-card-content',[_c('md-list',{staticClass:"nav-tabs title"},_vm._l((_vm.tabName),function(item,index){return _c('md-list-item',{key:item,class:[
          {active: _vm.isActivePanel(_vm.tabName[index])},
          ( _obj = {}, _obj[_vm.getColorButton(_vm.colorButton)] = _vm.isActivePanel(_vm.tabName[index]), _obj )],on:{"click":function($event){_vm.switchPanel(_vm.tabName[index])}}},[_c('p',[_vm._v(_vm._s(_vm.tabName[index]))]),(_vm.navPillsIcons)?_c('md-icon',[_vm._v(_vm._s(_vm.tabIcon[index]))]):_vm._e()],1)
  var _obj;})),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{staticClass:"tab-content"},_vm._l((_vm.tabName),function(item,index){return (_vm.isActivePanel(_vm.tabName[index]))?_c('div',{key:item,class:_vm.getTabContent(index + 1)},[_vm._t(_vm.getTabContent(index + 1),[_vm._v("This is the default text!")])],2):_vm._e()}))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }