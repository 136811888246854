<template>
  <div class="wrapper">
    <parallax
      class="section page-header header-filter"
      :style="headerStyle"
    ></parallax>
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-80 mx-auto">
              <div class="text-center">
                <div class="name">
                  <h1 class="title">Tarifs des séances de sophrologie</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mb-5">
            <h3 style="font-weight:bold">Séance individuelle&nbsp;:</h3>
            <p>Adultes : 90$ la séance de 60 min</p>
            <p>Adolescents - Enfants (5 à 18 ans) : 75$ la séance de 45 min</p>
            <strong>Avec reçus d’assurance</strong>
            <div class="description pb-5">
              <a
                href="https://square.site/book/CEK8ECP4E1Q1Y/clemence-bauche"
                target="_blank"
                rel="noopener"
                class="d-block"
              >
                <md-button class="md-success"
                  >Rendez-vous visioconférence</md-button
                >
              </a>
            </div>

            <h3 style="font-weight:bold">Relaxation personnalisée pour enfant de 3 à 12 ans&nbsp;(reprise en septembre) :</h3>
            <p>Votre enfant a entre 3 et 12 ans et rencontre des difficultés pour revenir au calme après une tempête émotionnelle, à s'endormir ou se sent stressé ? Après avoir remplie le questionnaire, recevez un audio personnalisée et une fiche pour accompagner votre enfant dans la détente.</p>
            <div class="description pb-5">
              <!-- <a
                href="https://checkout.square.site/buy/AXJ2KIKU7HDYPWBJ6BHTL566"
                target="_blank"
                rel="noopener"
                class="d-block"
              >
                <md-button class="md-success"
                  >En savoir plus</md-button
                >
              </a> -->
            </div>
            <!-- <p>
              Tous les lundi à partir du 9 septembre de 18h30 à 19h45 au centre Asha Yoga Laval.
              <a
                href="https://goo.gl/maps/JzDMYTXkTVAYSRpa9"
                target="_blank"
                rel="noopener"
              >Voir sur google Maps.</a>
            </p>
            <a
              href="https://clients.mindbodyonline.com/classic/ws?studioid=47910&stype=41&sTG=39&prodId=11541"
              target="_blank"
              rel="noopener"
            >
              <md-button class="md-info">Inscriptions aux séances de groupe</md-button>
            </a> -->
            <h3 style="font-weight:bold">Séances en entreprise:</h3>
            <span>Sur devis, me contacter <a href="mailto:clemence.sophrologie@gmail.com" target="_blank" rel="noopener">ici</a></span>

            <h3 style="font-weight:bold">Offrez une séance !</h3>
            <p>
              Prenez soin de vos proche et offrez leur une séance de
              sophrologie.
            </p>
            <a
              href="https://squareup.com/gift/1PTPD0NVKCQ4G/order"
              target="_blank"
              rel="noopener"
              class="d-block"
            >
              <md-button class="md-success"
                >Offrir dès maintenant une séance</md-button
              >
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { NavTabsCard } from "@/components";
// import { Tabs } from "@/components";

export default {
  bodyClass: "profile-page",
  components: {
    // NavTabsCard,
    // Tabs
  },
  metaInfo: {
    title: "Clémence Bauche - sophrologue - Séances, services et tarifs",
    meta: [
      {
        name: "description",
        content:
          "Clémence Bauche sophrologue méthode caycedienne à Montréal - Séances de sophrologie individuelles ou en groupe pour enfants, adolescents et adultes",
      },
    ],
    link: [
      { rel: "canonical", href: "https://sophrologie-montreal.ca/services/" },
    ],
  },
  data() {
    return {};
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/nature-3.jpg"),
    },
    img: {
      type: String,
      default: require("@/assets/img/faces/clemence_bauche.jpg"),
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.section
  padding: 0
</style>
