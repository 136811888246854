<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" :style="headerStyle"></parallax>
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-96 mx-auto">
              <div class="text-center">
                <div class="name">
                  <h1 class="title">Qu'est-ce que l'EFT&nbsp;?</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="description pb-3">
            <h3>Présentation de l’EFT&nbsp;:</h3>
            <p>
              “Emotional Freedom Technique” ou Technique de Libération Émotionnelle est une technique qui vous l’aurez compris, vous permet de libérer certaines émotions qui ont un impact négatif sur votre corps et votre esprit et qui influencent votre vie.
              C’est en stimulant des points précis se trouvant sur les méridiens que Clémence vous guidera afin de permettre une nouvelle circulation énergétique qui vous aidera en complément de la sophrologie à vous libérer de certaines souffrances physiques et psychologiques.
            </p>
          </div>
          <div class="description pb-3">
            <h3>À qui s’adresse l’EFT&nbsp;?</h3>
            <p>L’EFT s’adresse à tous à partir de 5 ans et plus ! Il est nécessaire de prendre soin de vous tout au long de votre vie et d’aller vers une méthode qui vous convient pour pouvoir avancer dans votre vie sereinement et avec confiance</p>
          </div>
          <div class="description pb-3">
            <h3>Quels sont les champs d’action de l’EFT&nbsp;?</h3>
            <p>L’EFT peut vous être recommandée pour vous aider sur&nbsp;<br><br>
              - Traumatisme et stress post-traumatique (témoin.te ou victime d’un événement traumatisant avec ou sans flash-back)<br>
              - Phobies<br>
              - Douleurs chroniques<br>
              - Dépression<br>
              - Gestion du stress<br>
              - Préparation à un événement ou à un examen<br>
            </p>
          </div>
          <div class="description pb-3">
            <h3>Mot de votre thérapeute&nbsp;:</h3>
            <p>
              J’ai souhaité me former à l’EFT afin d’avoir de nouveaux outils à vous proposer en complément de la sophrologie. Tous deux sont complémentaires et très efficace ensemble ou séparément. 
              <br>Bien entendu ces méthodes ne remplacent en aucun cas la consultation, le diagnostic et l’avis d’un médecin médical.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  bodyClass: "profile-page",
  metaInfo: {
    title: "Clémence Bauche - Qu'est-ce que l'EFT ?",
    meta: [
      {
        name: "description",
        content:
          "Sophrologue méthode caycedienne à Montréal - “Emotional Freedom Technique”, Technique de Libération Émotionnelle, libérer certaines émotions qui ont un impact négatif sur votre corps et votre esprit et qui influencent votre vie"
      }
    ],
    link: [
      { rel: "canonical", href: "https://sophrologie-montreal.ca/sophrologie/" }
    ]
  },
  data() {
    return {};
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/profile_city.jpg")
    },
    img: {
      type: String,
      default: require("@/assets/img/faces/clemence_bauche.jpg")
    }
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    }
  }
};
</script>

<style lang="sass" scoped>
.section
  padding: 0
</style>
