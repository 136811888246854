<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" :style="headerStyle"></parallax>
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 mx-auto">
              <div class="profile">
                <div class="avatar">
                  <img :src="img" alt="Circle Image" class="img-raised rounded-circle img-fluid">
                </div>
                <div class="name">
                  <h1 class="title h3">Clémence Bauche Sophrologue</h1>
                  <md-button
                    href="https://www.facebook.com/clemence.sophrologue/"
                    target="_blank"
                    rel="noopener"
                    class="md-just-icon md-simple"
                  >
                    <i class="fab fa-facebook-square"></i>
                  </md-button>
                  <md-button
                    href="https://www.linkedin.com/in/clémence-bauche-3ba825135"
                    target="_blank"
                    rel="noopener"
                    class="md-just-icon md-simple"
                  >
                    <i class="fab fa-linkedin"></i>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
          <div class="description pb-5">
            <p>
              Après avoir obtenue son Diplôme d’État en tant qu’infirmière en France, Clémence a souhaité élargir ses compétences afin de proposer de nouvelles techniques de soins à ses patients. Pour cela, elle a effectué une formation afin d’obtenir le diplôme de sophrologue au sein de L’institut National de l’Enseignement de la Sophrologie à Paris.
              <br>Durant sa formation Clémence suivra 2 séminaires de spécialisation sur la gestion du stress et du sommeil. Arrivée courant 2017 à Montréal, elle souhaite partager ses connaissances afin de vous accompagner et de vous aider à avancer dans la vie.
            </p>
          </div>

          <!-- <div class="description text-center mb-5">
            <h2 class="title">Où me trouver&nbsp;?</h2>
            <a
              href="https://maisonlouvgang.com/"
              target="_blank"
              rel="noopener"
              class="p"
            >Louvgang &nbsp;:</a>
            <address>76 avenue Mozart O., Montreal, QC</address>
            <br>
            <a
              href="https://osteopathieberri.com/"
              target="_blank"
              rel="noopener"
              class="p"
            >Centre Ostéopathie Berri &nbsp;:</a>
            <address>1175 RUE SAINT-HUBERT, H2L 3Y8, MONTREAL, QC</address>
          </div> -->
          <div class="md-layout">
            <div class="elfsight-app-7a170906-fef3-433c-bb35-4756dd02077d"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  bodyClass: "profile-page",
  metaInfo: {
    title: "Clémence Bauche - sophrologue - Profil",
    meta: [
      {
        name: "description",
        content:
          "Clémence Bauche sophrologue méthode caycedienne à Montréal - Mon parcours et mes expériences en tant que sophrologue"
      }
    ],
    link: [
      { rel: "canonical", href: "https://sophrologie-montreal.ca/profil/" }
    ]
  },
  data() {
    return {};
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/nature.jpg")
    },
    img: {
      type: String,
      default: require("@/assets/img/faces/clemence_bauche.jpg")
    }
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    }
  }
};
</script>

<style lang="sass" scoped>
.section
  padding: 0

@media (max-width: 991px)
  .profile-page
    .page-header  
      height: 260px
</style>
