<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" :style="headerStyle"></parallax>
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-96 mx-auto">
              <div class="text-center">
                <div class="name">
                  <h1 class="title">Qu'est-ce que la sophrologie&nbsp;?</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="description pb-3">
            <h3>Présentation de la sophrologie&nbsp;:</h3>
            <p>
              La sophrologie sert à retrouver une harmonie entre votre physique (corps) et votre psychique (esprit). La sophrologue vous accompagne dans une détente proche du sommeil par le biais d’exercices basés sur la respiration.
              La sophrologie peut vous aider sur plusieurs aspects :
              le stress,
              les phobies,
              les différents troubles (sommeil, alimentaire, addictif, concentration etc…)
              la confiance en soi.
              Elle peut également accompagner sur le long terme comme pour la préparation à l’accouchement ou la réalisation d’un projet (examen, compétition…).
              Attention la sophrologie ne remplace en aucun cas la prise d’un traitement médical ou le suivi auprès d’un psychologue ou d’un psychiatre.
            </p>
          </div>
          <div class="description pb-3">
            <h3>À qui s’adresse la sophrologie&nbsp;?</h3>
            <p>La sophrologie s’adresse à toutes les personnes qui le souhaitent et en ressentent le besoin. Les séances peuvent débuter dès de l’âge de 5 ans et jusqu’à ce que vous soyez en état d’entendre la voix qui vous guide. Elle peut être contre-indiquée pour certaine maladie psychiatrique comme le trouble dissociatif de l’identité.</p>
          </div>
          <div class="description pb-3">
            <h3>Comment se déroule une séance de sophrologie&nbsp;?</h3>
            <p>Après une anamnèse afin de définir les problèmes ou les besoins, Clémence adaptera son protocole et sa séance pour pouvoir vous aider le plus efficacement possible. Puis, durant toute la durée de la séance, votre sophrologue vous accompagnera au son de sa voix. Vous effectuerez des exercices de respiration tout en adoptant parfois différentes postures (assis, debout, allongée si besoin).</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  bodyClass: "profile-page",
  metaInfo: {
    title: "Clémence Bauche - Qu'est-ce que la sophrologie ?",
    meta: [
      {
        name: "description",
        content:
          "Sophrologue méthode caycedienne à Montréal - Présentation de la sophrologie, à qui elle s'adresse, comment se déroule une séance de sophrologie"
      }
    ],
    link: [
      { rel: "canonical", href: "https://sophrologie-montreal.ca/sophrologie/" }
    ]
  },
  data() {
    return {};
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/profile_city.jpg")
    },
    img: {
      type: String,
      default: require("@/assets/img/faces/clemence_bauche.jpg")
    }
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    }
  }
};
</script>

<style lang="sass" scoped>
.section
  padding: 0
</style>
